/* global Vue */
require('url-polyfill');
require('url-search-params-polyfill');

function getFilterValues(_this, property) {
  /**
   * return an array of all the values from a certain property,
   * for getting all the filters we need in the sidebar
   */
  return _this.items.reduce((accumulator, currentValue) => {
    currentValue[property].forEach(item => {
      if (accumulator.indexOf(item) === -1) {
        accumulator.push(item);
      }
    });
    return accumulator;
  }, []);
}

export default {
  init() {
    if (!document.getElementById('program-list')) {
      return;
    }
    Vue.component('program-list', {
      data() {
        return {
          items: [],
          sortBy: 'department',
          currType: 'program_type_all',
          currDivision: 'program_division_all',
          currLocation: 'program_location_all',
          showFilters: false,
          filterUlr: null,
          history: [],
        };
      },

      props: ['url'],

      created() {
        $.getJSON(this.url, json => {
          this.items = _.sortBy(json, [item => item.id]);
        });
        this.getFiltersUrlState();
      },

      template: `
        <div class="program-finder">
          <div class="program-controls">
             <button
              v-on:click="toggleFilters"
              class="program__filters-btn"
              v-bind:aria-expanded="showFilters ? 'true' : 'false' " >
              Filters
            </button>
            <div class="program--list__sort form--select">
              <label class="form__label" for="sort-by-tablet">Sort By</label>
              <select class="form__control" v-model="sortBy" id="sort-by-tablet">
                <option value="department">Department</option>
                <option value="alphabetical">Alphabetical</option>
              </select>
            </div>
          </div>
          <div class="program__filters">
            <div class="program__filters-inner">
              <fieldset class="program__filters__fieldset">
              <legend>Show Me</legend>
              <div class="program__filters__field radio-holder">
              <label for="program-type-all">
                <input
                  type="radio"
                  name="program-type"
                  v-model="currType"
                  id="program-type-all"
                  value="program_type_all" />
                <span class="control-text">All Program Types</span>
              </label>
              </div>
              <div class="program__filters__field radio-holder" v-for="(item, index) in programType">
              <label v-bind:for="'program-type-'+index">
                <input
                  type="radio"
                  name="program-type"
                  v-model="currType"
                  v-bind:id="'program-type-'+index"
                  v-bind:value="item" />
                <span class="control-text">{{ item }}</span>
              </label>
              </div>
            </fieldset>
              <fieldset class="program__filters__fieldset">
                <legend>Interests</legend>
                <div class="program__filters__field radio-holder">
                <label for="program-division-all">
                  <input
                    type="radio"
                    name="program-division"
                    v-model="currDivision"
                    id="program-division-all"
                    value="program_division_all" />
                  <span class="control-text">All Interests</span>
                </label>
                </div>
                <div class="program__filters__field radio-holder" v-for="(item, index) in programDivision">
                <label v-bind:for="'program-division-'+index">
                  <input
                    type="radio"
                    v-model="currDivision"
                    name="program-division"
                    v-bind:id="'program-division-'+index"
                    v-bind:value="item" />
                  <span class="control-text">{{ item }}</span>
                </label>
                </div>
              </fieldset>
              <fieldset class="program__filters__fieldset">
                <legend>Located At</legend>
                <div class="program__filters__field radio-holder">
                <label for="program-location-all">
                  <input
                    type="radio"
                    name="program-location"
                    v-model="currLocation"
                    id="program-location-all"
                    value="program_location_all" />
                  <span class="control-text">Any Location or Online</span>
                </label>
                </div>
                <div class="program__filters__field radio-holder" v-for="(item, index) in location">
                <label v-bind:for="'program-location-'+index">
                  <input
                    type="radio"
                    v-model="currLocation"
                    name="program-location"
                    v-bind:id="'program-location-'+index"
                    v-bind:value="item" />
                  <span class="control-text">{{ item }}</span>
                </label>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="program--list">
            <div class="program--list__sort form--select">
              <label class="form__label" for="sort-by">Sort By</label>
              <select class="form__control" v-model="sortBy" id="sort-by">
                <option value="department">Department</option>
                <option value="alphabetical">Alphabetical</option>
              </select>
            </div>
            <div v-if="!filteredResults.length"><h2>No Results</h2></div>
            <div class="program--list__block" v-for="item in groupedResults">
              <h2 class="program--list__heading">{{ item.type }}</h2>
              <ul class="program--list__items">
                <li class="program--list__item" v-for="subitem in item.data">
                  <h3 class="program--list__title h4">
                    <a class="program--list__link" v-bind:href="subitem.url">
                      {{ subitem.title }}
                    </a>
                  </h3>
                  <span class="program--list__cred"> {{ subitem.credentialType.join(', ') }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      `,

      filters: {
        formatId(value) {
          return value.replace(/\W/g, '_').toLowerCase();
        },
      },

      watch: {
        currType() {
          this.setFiltersUrlState();
        },

        currDivision() {
          this.setFiltersUrlState();
        },

        currLocation() {
          this.setFiltersUrlState();
        },

        sortBy() {
          this.setFiltersUrlState();
        },
      },

      computed: {
        filteredResults() {
          let filteringTemp = this.items;

          // Filtering logic for each filter
          if (this.currDivision !== 'program_division_all') {
            filteringTemp = filteringTemp.filter(item => {
              return item.programDivision.indexOf(this.currDivision) !== -1;
            });
          }

          if (this.currLocation !== 'program_location_all') {
            filteringTemp = filteringTemp.filter(item => {
              return item.location.indexOf(this.currLocation) !== -1;
            });
          }

          if (this.currType !== 'program_type_all') {
            filteringTemp = filteringTemp.filter(item => {
              return item.programType.indexOf(this.currType) !== -1;
            });
          }

          return filteringTemp;
        },

        // Get the items for each filter category
        programType() {
          return getFilterValues(this, 'programType');
        },

        programDivision() {
          return getFilterValues(this, 'programDivision');
        },

        location() {
          return getFilterValues(this, 'location');
        },

        // Sortby logic
        groupedResults() {
          if (this.sortBy === 'department') {
            // Create a new program array with each item inside a nested array, grouped by dept
            const temp = this.filteredResults.reduce((acc, obj) => {
              // Drill down to each department item for each program
              obj.department.forEach(item => {
                /**
                 * Add program to the appropiate department array,
                 * Create new department array if there isn't one
                 */
                if (!acc[item]) {
                  acc[item] = [];
                }
                acc[item].push(obj);
              });
              return acc;
            }, {});
            return _(temp)
              .map((value, key) => {
                /**
                 * re-organize that array item so that it has the
                 * department and data as separate property/value pairs
                 * and sort it
                 */
                return {
                  type: key,
                  data: value,
                };
              })
              .sortBy([item => item.type.toLowerCase()])
              .value();
            // eslint-disable-next-line no-else-return
          } else {
            return _(this.filteredResults)
              .sortBy(item => item.title.toUpperCase())
              .groupBy(item => item.title[0].toUpperCase())
              .map((value, key) => {
                return {
                  type: key,
                  data: value,
                };
              })
              .value();
          }
        },
      },

      methods: {
        toggleFilters() {
          $('.program__filters').slideToggle();
          $('.program__filters-btn').toggleClass('open');
          this.showFilters = !this.showFilters;
        },

        getFiltersUrlState() {
          this.filterUlr = new URL(window.location);
          this.currType = !this.filterUlr.searchParams.get('currType')
            ? 'program_type_all'
            : this.filterUlr.searchParams.get('currType');
          this.currDivision = !this.filterUlr.searchParams.get('currDivision')
            ? 'program_division_all'
            : this.filterUlr.searchParams.get('currDivision');
          this.currLocation = !this.filterUlr.searchParams.get('currLocation')
            ? 'program_location_all'
            : this.filterUlr.searchParams.get('currLocation');
          this.sortBy = !this.filterUlr.searchParams.get('sortBy')
            ? 'department'
            : this.filterUlr.searchParams.get('sortBy');
        },

        setFiltersUrlState() {
          if (!this.filterUlr.searchParams.get('currType')) {
            this.filterUlr.searchParams.append('currType', this.currType);
          } else {
            this.filterUlr.searchParams.set('currType', this.currType);
          }

          if (!this.filterUlr.searchParams.get('currDivision')) {
            this.filterUlr.searchParams.append(
              'currDivision',
              this.currDivision,
            );
          } else {
            this.filterUlr.searchParams.set('currDivision', this.currDivision);
          }

          if (!this.filterUlr.searchParams.get('currLocation')) {
            this.filterUlr.searchParams.append(
              'currLocation',
              this.currLocation,
            );
          } else {
            this.filterUlr.searchParams.set('currLocation', this.currLocation);
          }

          if (!this.filterUlr.searchParams.get('sortBy')) {
            this.filterUlr.searchParams.append('sortBy', this.sortBy);
          } else {
            this.filterUlr.searchParams.set('sortBy', this.sortBy);
          }

          window.history.pushState({}, '', this.filterUlr.href);
        },
      },
    });

    // eslint-disable-next-line no-new
    new Vue({ el: '#program-list' });
  },
};
