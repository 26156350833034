import '@babel/polyfill';
import 'slick-carousel';
import 'object-fit-images';

import accordion from './object/accordion';
import instagramLoader from './object/instagram';
import twitterLoader from './object/twitter';
import carousel from './object/carousel';
import programFinder from './object/program-finder';
import mainHeaderSearch from './object/main-header-search';
import mainMenu from './object/main-menu';
import wowAnimate from './object/wow-animation';
import fancyboxPopups from './object/fancyboxPopups';
import imgCoverBg from './object/img-cover-bg';
import facultyTable from './object/facultyTable';
import alert from './object/alert';
import wrapWord from './object/wrapWord';
import eventsPage from './object/eventsPage';
import communityPageEvents from './object/community-page-events';
import homePageEvents from './object/home-page-events';

window.$ = require('jquery');
window.jQuery = require('jquery');
const objectFitImages = require('object-fit-images');
window.fancybox = require('@fancyapps/fancybox');

$(() => {
  accordion.init();
  instagramLoader.init();
  twitterLoader.init();
  carousel.init();
  programFinder.init();
  mainMenu.init();
  mainHeaderSearch.init();
  wowAnimate.init();
  fancyboxPopups.init();
  imgCoverBg.init();
  objectFitImages();
  facultyTable.init();
  alert.init();
  eventsPage.init();
  homePageEvents.init();
  communityPageEvents.init();
  wrapWord.init();

  setTimeout(() => {
    $('.twitter__body').html(function replace() {
      return $(this)
        .html()
        .replace('RT ', '<div class="twitter__title">')
        .replace(': ', '</div>');
    });
  }, 500);
});
