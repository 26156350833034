import firstEvents from './firstEvents';

const communityPageEvents = (() => {
  function init() {
    const $eventsContainer = $('.events__item--container');

    if (!$eventsContainer.length) {
      return;
    }

    const itemsQty = 2;
    const descr = 'Community';
    const itemClassName = 'events__item events__item--lg';

    firstEvents.init($eventsContainer, itemsQty, descr, itemClassName);
  }

  return {
    init,
  };
})();

export default communityPageEvents;
