const wrapWord = (() => {
  function init() {
    const $titleLink = $('a.title-link').length ? $('a.title-link') : $('.title-link a');

    if (!$titleLink.length) {
      return;
    }

    $titleLink.each((i, el) => {
      const badge = el.querySelector('.title-link-badge');
      const oldNode = el.firstChild;
      const lastWord = oldNode.splitText(oldNode.data.lastIndexOf(' ') + 1);

      const span = document.createElement('span');

      span.classList.add('word-wrapper');
      span.append(lastWord, badge);
      el.append(span);
    });
  }

  return {
    init,
  };
})();

export default wrapWord;