const facultyTable = (() => {
  require('datatables.net-dt')();
  require('datatables.net-buttons')();

  const renameLabel = type => {

    if (type.toUpperCase() === 'NAME & TITLE') {
      return 'Type';
    }

    if (type.toUpperCase() === 'OFFICE LOCATION') {
      return 'Location';
    }

    return type.toLowerCase();
  };

  const removeTabIndex = (table) => {
    $(table).find('td:first-child').each( (i, element) => {
      $(element).removeAttr('tabindex');
    });
  };

  const init = () => {
    const ajaxPath = $('#faculty-table').data('url');
    const $tableHolder = $('#faculty-table');

    const table = $tableHolder.DataTable({
      ajax: ajaxPath,
      processing: true,
      pagingType: 'simple_numbers',
      sPaginationType: 'ellipses',
      deferLoading: 57,

      language: {
        search: 'Search Within Table',
        infoFiltered: '',
        infoEmpty: '',
        info: `<b>_START_-_END_</b>
               <span>of</span>
               <b>_TOTAL_</b>`,
        sLengthMenu:
          `<div class="table-length-wrap">
             <select name="table-length" class="table-length form__control">
               <option value="10">Show <span>10</span></option>
               <option value="20">Show <span>20</span></option>
               <option value="30">Show <span>30</span></option>
               <option value="40">Show <span>40</span></option>
               <option value="50">Show <span>50</span></option>
               <option value="-1">All</option>
             </select>
             <span class="per-page">per page</span>
           </div>`,
      },
      columns: [
        {
          data: 'status',
        },
        {
          data: 'program',
        },
        {
          data: 'email',
        },
        {
          data: 'phone',
        },
        {
          data: 'location',
        },
      ],
      columnDefs: [
        {
          targets: 0,
          data: 'status',
          orderable: false,
          render(data, type, row) {
            if (row.url) {
              return (
                `<div class="row-section">
                  <span class="row-label">Name & Title</span>
                  <a class="link-name" href="${row.url}">${row.name}</a>
                  <span class="type">${data}</span>
                </div>`
              );
            }
            return (
              `<div class="row-section">
                <span class="row-label">Name</span>
                <span class="link-name" >${row.name}</span>
                <span class="type">${data}</span>
              </div>`
            );
          },
        },
        {
          targets: 1,
          data: 'program',
          render(data, type, row) {
            return `<div class="row-section"><span class="row-label">Department</span><a class="program" href="${
              row.email
            }">${data}</a></div>`;
          },
        },
        {
          targets: 2,
          data: 'email',
          render(data, type, row) {
            return `<div class="row-section"><span class="row-label">Email</span><a class="email" href="${
              row.email
            }">${data}</a></div>`;
          },
        },
        {
          targets: 3,
          data: 'phone',
          render(data, type, row) {
            return `<div class="row-section"><span class="row-label">Phone</span><a class="tel" href="tel:${
              row.phone
            }">${data}</a></div>`;
          },
        },
        {
          targets: 4,
          data: 'location',
          render(data, type, row) {
            return `<div class="row-section"><span class="row-label">Office Location</span><span class="location"
            }">${data}</span></div>`;
          },
        },
        {
          targets: '_all',
          orderable: false,
        },
      ],
      dom:
        '<"heading-table"<"heading-table-filter"B<"mobile-filter-drop"<"toolbar">rf>>><"wrapper-table"t><"footer-table"p<"table-info"li>>',
      buttons: [],
      keys: true,
      responsive: true,
      fnDrawCallback(oSettings) {
        /** remove pagination if we have one page **/
        $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
        $(oSettings.nTableWrapper).find('.dataTables_info').show();

        if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay() || oSettings._iDisplayLength === -1) {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
        }

        if (oSettings.fnRecordsDisplay() === 0) {
          $(oSettings.nTableWrapper).find('.dataTables_info').hide();
        }

        /** remove tabindex from columns if we have one page **/
        setTimeout(() => removeTabIndex($(oSettings.nTable), 0));
      },
      initComplete() {
        this.api()
          .columns()
          .every(function (i) {
            if (i > 0 && i !== 2 && i !== 3) {
              const column = this;
              const info = this.page.info();
              const toolbar = $('.toolbar');
              const holder = document.createElement('div');
              const label = document.createElement('label');
              const searchHolder = $('#faculty-table_filter');
              const select = $(
                '<select class="form__control filter"><option value="">All</option></select>',
              );

              $(label).text(renameLabel(this.header().innerText));

              $(label)
                .addClass('form__label')
                .attr('for', `input-${i}`)
                .appendTo(holder);
              select.attr('id', `input-${i}`).appendTo(holder);
              $(holder)
                .addClass('filter-box')
                .appendTo(toolbar);
              searchHolder.appendTo(toolbar);

              select.on('change', function () {
                const val = $.fn.dataTable.util.escapeRegex($(this).val());
                column.search(val ? `${val}` : '', true, false).draw();
              });

              column
                .data()
                .unique()
                .sort()
                .each(function (d, j) {
                  select.append(`<option value="${d}">${d}</option>`);
                });
            }
          });
      },
    });

    const renderBtnClear = container => {
      const btnClear = document.createElement('button');
      const iconClear = document.createElement('i');
      const filterHolder = $(container);
      $(btnClear)
        .addClass('clear-table')
        .html('<span class="sr-only">Reset filter</span>');
      $(iconClear)
        .addClass('icon icon-refresh')
        .attr('aria-hidden', 'true');
      $(iconClear).appendTo(btnClear);
      $(btnClear).insertAfter(filterHolder);
    };

    const resetFilter = () => {
      $('.clear-table').on('click', function (e) {
        e.preventDefault();
        $('.filter').prop('selectedIndex', 0);
        table
          .search('')
          .columns()
          .search('')
          .draw();
      });
    };

    table.on('init.dt', function () {
      renderBtnClear('.dataTables_filter');
      resetFilter();
      /** Remove tabimdex from first column **/
      removeTabIndex(this);
    });

    /** Scroll to top after go on next page **/
    table.on('page.dt', function() {
      if ($(window).width() > 1330) {
        $('html, body').animate({
          scrollTop: $(this).offset().top
        }, 'slow');
      } else {
        $('html, body').animate({
          scrollTop: $(this).offset().top - 90
        }, 'slow');
      }
    });
  };
  return {
    init,
  };
})();

export default facultyTable;
