const mainHeaderSearch = (() => {
  function init() {
    const $body = $('body');
    const $searchOpener = $('.search-opener');
    const SEARCH_ACTIVE_CLASSNAME = 'search-active';
    const $searchinput = $('.main-header .search-box input[type="search"]');
    const $searchSubmitBtn = $('.main-header .search-box .start-search');
    const TIMEOUT_DELAY = 300;

    $searchOpener.attr('aria-expanded', 'false');

    $searchOpener.click(() => {
      $body.toggleClass(SEARCH_ACTIVE_CLASSNAME);
      $searchOpener.attr('aria-expanded', $searchOpener.attr('aria-expanded') === 'false' ? 'true' : 'false');

      setTimeout(() => {
        $searchinput.focus();
      }, TIMEOUT_DELAY);
    });

    $searchSubmitBtn.on('keydown', (e) => {
      if (e.keyCode === 9 && e.shiftKey === false) {
        e.preventDefault();
        $searchOpener.focus();
      }
    });

    $searchOpener.on('keydown', (e) => {
      if (e.keyCode === 9 && e.shiftKey === true && $body.hasClass(SEARCH_ACTIVE_CLASSNAME)) {
        e.preventDefault();
        $searchSubmitBtn.focus();
      }
    });
  }

  return {
    init,
  }
})();

export default mainHeaderSearch;