/**
 * Accordion display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if accordion present
 */
const $ = require('jquery');

const accordion = (() => {
  // Expand/collapse accordion item.

  const $accordion = $('.accordion');
  const $expandAll = $accordion.find('.accordion__expand-all');
  const $collapseAll = $accordion.find('.accordion__collapse-all');

  function accordionToggle() {
    const parentAccordion = $(this).closest('.accordion');
    const numItems = parentAccordion.find('.accordion__item').length;

    if ($(this).hasClass('js-expanded')) {
      $(this)
        .attr('aria-expanded', 'false')
        .removeClass('js-expanded')
        .next('.accordion__content')
        .slideUp(300)
        .attr('aria-hidden', 'true');
    } else {
      $(this)
        .attr('aria-expanded', 'true')
        .addClass('js-expanded')
        .next('.accordion__content')
        .slideDown(300)
        .attr('aria-hidden', 'false');
    }

    const openItems = $accordion.find('.js-expanded').length;

    if (openItems > 0) {
      $collapseAll
        .removeClass('js-disabled')
        .removeAttr('aria-expanded')
        .prop('disabled', false);
    } else {
      $collapseAll
        .addClass('js-disabled')
        .attr('aria-expanded', 'true')
        .prop('disabled', true);
    }

    if (openItems < numItems) {
      $expandAll
        .removeClass('js-disabled')
        .removeAttr('aria-expanded')
        .prop('disabled', false);
    } else {
      $expandAll
        .addClass('js-disabled')
        .attr('aria-expanded', 'true')
        .prop('disabled', true);
    }
  }

  // Expand-all toggle functionality.
  function accordionExpandAll() {
    const accordionItems = $(this)
      .parent()
      .siblings('.accordion__items')
      .find('.accordion__toggle');
    const $accordionContent = $(this)
      .parent()
      .siblings('.accordion__items')
      .find('.accordion__content');

    $accordionContent.attr('aria-hidden', 'false');
    $(accordionItems).attr('aria-expanded', 'true').addClass('js-expanded');
    $accordionContent.slideDown(300);

    $(this)
      .addClass('js-disabled')
      .attr('aria-expanded', 'true')
      .prop('disabled', true);
    $(this)
      .siblings('.accordion__collapse-all')
      .removeClass('js-disabled')
      .removeAttr('aria-expanded')
      .prop('disabled', false);
    $collapseAll.focus();
  }

  // Collapse-all toggle functionality.
  function accordionCollapseAll() {
    const accordionItems = $(this)
      .parent()
      .siblings('.accordion__items')
      .find('.accordion__toggle');

    const $accordionContent = $(this)
      .parent()
      .siblings('.accordion__items')
      .find('.accordion__content');

    $accordionContent.attr('aria-hidden', 'true');
    $(accordionItems).attr('aria-expanded', 'false').removeClass('js-expanded');
    $accordionContent
      .slideUp(300)
      .addClass('js-disabled')
      .attr('aria-expanded', 'false')
      .prop('disabled', true);

    $(this)
      .addClass('js-disabled')
      .attr('aria-expanded', 'true')
      .prop('disabled', true);
    $(this)
      .siblings('.accordion__expand-all')
      .removeClass('js-disabled')
      .removeAttr('aria-expanded')
      .prop('disabled', false);
    $expandAll.focus();
  }

  // Accordion item back-to-top functionality.
  function accordionBackTop() {
    const headerHeight = $('header').height();

    const accordionTop = $(this)
      .closest('.accordion')
      .find('.accordion__all');

    $('html, body').animate(
      {
        scrollTop: accordionTop.offset().top - headerHeight,
      },
      300,
    );

    $collapseAll.focus();
  }

  const init = () => {
    if (!$accordion.length) {
      return;
    }

    $accordion.each((i, el) => {
      const $accordionEl = $(el);
      const openItems = $accordionEl.find('.js-expanded').length;
  
      if (openItems > 0) {
        $collapseAll
          .removeClass('js-disabled')
          .removeAttr('aria-expanded')
          .prop('disabled', false);
      } else {
        $collapseAll
          .addClass('js-disabled')
          .attr('aria-expanded', 'true')
          .prop('disabled', true);
      }
    });

    $('.accordion__toggle').on('click', accordionToggle);
    $('.accordion__expand-all').on('click', accordionExpandAll);
    $('.accordion__collapse-all').on('click', accordionCollapseAll);
    $('.accordion__top').on('click', accordionBackTop);
  };

  return {
    init,
  };
})();

export default accordion;
