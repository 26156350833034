const $ = require('jQuery');

const mainMenu = (() => {
  const $window = $(window);
  const $document = $(document);
  const $body = $('body');
  const $navHolder = $('.main-nav-box');
  const $nav = $navHolder.find('.nav-holder');
  const $mobileNav = $navHolder.find('.mobile-info-nav');
  const $mainNavTitleBtn = $navHolder.find('.main-nav-title .main-nav-back');
  const $navOpener = $('.main-nav-opener');
  const $mobileNavOpener = $('.main-nav-opener.mobile');
  const $submenuOpener = $nav.find('.open-submenu');
  const IS_TOP_LVL_MENU_CLASSNAME = 'menu-lvl-down';
  const $everyLastLinkMenu = $('.sub-menu__item:last-child > a:last-of-type');
  const $mobileNavInfoLink = $($('.mobile-info-list__item a')[0]);
  const ACTIVE_MENU_CLASS = 'active-main-menu';
  const OPENED_SUBMENU_CLASSNAME = 'active-submenu';
  const $lastTopFocusElement = $('.info-nav-list__item').last().find('a').focus();
  const ANIMATION_SPEED = 200;
  let topPosition = 0;
  
  function init() {
    _addListeners();
  }

  function _addListeners() {
    $navOpener.click(() => {
      $navOpener.attr('aria-expanded', $navOpener.attr('aria-expanded') === 'false' ? 'true' : 'false');
      $navHolder.toggleClass(ACTIVE_MENU_CLASS);
      _removeBodyScroll();
      $nav.attr('aria-hidden', $nav.attr('aria-hidden') === 'false' ? 'true' : 'false');
    });

    $mobileNavOpener.click(() => {
      $mobileNav.attr('aria-hidden', $mobileNav.attr('aria-hidden') === 'false' ? 'true' : 'false');
    });

    $submenuOpener.click((e) => {
      const $this = $(e.currentTarget);

      if (!$this.hasClass(OPENED_SUBMENU_CLASSNAME)) {
        $this.addClass(OPENED_SUBMENU_CLASSNAME)
          .closest('.main-nav-list, .secondary-nav-list')
          .siblings('.main-nav-list, .secondary-nav-list, .info-nav-holder')
          .animate({opacity: 0}, ANIMATION_SPEED)
          .slideUp(ANIMATION_SPEED);

        $this.parent('.main-nav-list__item, .secondary-nav-list__item, .sub-menu__item')
          .siblings('.main-nav-list__item, .secondary-nav-list__item, .sub-menu__item')
          .animate({opacity: 0}, ANIMATION_SPEED).slideUp(ANIMATION_SPEED);

        $mainNavTitleBtn.text('Main Menu').addClass(IS_TOP_LVL_MENU_CLASSNAME);
        
        setTimeout(() => {
          $this.attr('aria-expanded', 'true')
            .siblings('.sub-menu')
            .slideDown(ANIMATION_SPEED/2)
            .animate({opacity: 1}, ANIMATION_SPEED)
            .attr('aria-hidden', 'false');
        }, ANIMATION_SPEED);
      } else {
        $this.attr('aria-expanded', 'false').siblings('.sub-menu')
          .animate({opacity: 0}, ANIMATION_SPEED)
          .slideUp(ANIMATION_SPEED).attr('aria-hidden', 'true');
        
        setTimeout(() => {
          $this.removeClass(OPENED_SUBMENU_CLASSNAME);

          if($this.parent('.main-nav-list__item, .secondary-nav-list__item').length) {
            $('.main-nav-list, .secondary-nav-list, .info-nav-holder')
            .slideDown(ANIMATION_SPEED)
            .animate({opacity: 1}, ANIMATION_SPEED);
            $mainNavTitleBtn.text('Home').removeClass(IS_TOP_LVL_MENU_CLASSNAME);
          }

          $this.parent('.main-nav-list__item, .secondary-nav-list__item, .sub-menu__item')
            .siblings('.main-nav-list__item, .secondary-nav-list__item, .sub-menu__item')
            .slideDown(ANIMATION_SPEED)
            .animate({opacity: 1}, ANIMATION_SPEED);
        }, ANIMATION_SPEED);
      }
    });
    
    $mainNavTitleBtn.click((e) => {
      if($mainNavTitleBtn.hasClass(IS_TOP_LVL_MENU_CLASSNAME)) {
        e.preventDefault();
      }

      $mainNavTitleBtn.text('Home').removeClass(IS_TOP_LVL_MENU_CLASSNAME);

      $navHolder.find('.sub-menu')
        .animate({opacity: 0}, ANIMATION_SPEED)
        .slideUp(ANIMATION_SPEED).attr('aria-hidden', 'true');
      
        setTimeout(() => {
          $('.main-nav-list__item, .sub-menu__item, .secondary-nav-list__item')
          .slideDown(ANIMATION_SPEED)
          .animate({opacity: 1}, ANIMATION_SPEED);
  
          $('.main-nav-list, .secondary-nav-list, .info-nav-holder')
            .slideDown(ANIMATION_SPEED)
            .animate({opacity: 1}, ANIMATION_SPEED);
        }, ANIMATION_SPEED);
        
      $submenuOpener.removeClass(OPENED_SUBMENU_CLASSNAME).attr('aria-expanded', 'false');
    });

    $everyLastLinkMenu.on('keydown', (e) => {
      if (e.keyCode === 9 && e.shiftKey === false && !$(e.target).siblings('.active-submenu').length) {
        e.preventDefault();
        $mainNavTitleBtn.focus();
      }
    });

    $mainNavTitleBtn.on('keydown', (e) => {
      if (e.keyCode === 9 && e.shiftKey === true && $('.active-submenu').length) {
        e.preventDefault();
        const $activeSubmenuItem = $($('.active-submenu')[$('.active-submenu').length -1]);

        if(!$activeSubmenuItem.siblings('.sub-menu').length) {
          $activeSubmenuItem.siblings('a').focus();
        } else {
          $activeSubmenuItem.siblings('.sub-menu').children('li:last-child').find('a').focus();
        }
      }
    });

    $navOpener.on('keydown', (e) => {
      if (e.keyCode === 9 && e.shiftKey === true && $navHolder.hasClass(ACTIVE_MENU_CLASS)) {
        e.preventDefault();
        $lastTopFocusElement.focus();
      }
    });

    $mobileNavOpener.on('keydown', e => {
      if (e.keyCode === 9 && e.shiftKey === false && $navHolder.hasClass(ACTIVE_MENU_CLASS) && $window.width() < 750) {
        e.preventDefault();
        $mobileNavInfoLink.focus();
      }
    });

    $mobileNavInfoLink.on('keydown', (e) => {
      if (e.keyCode === 9 && e.shiftKey === true && $navHolder.hasClass(ACTIVE_MENU_CLASS) && $window.width() < 750) {
        e.preventDefault();
        $mobileNavOpener.focus();
      }
    });

    $lastTopFocusElement.on('keydown', (e) => {
      if (e.keyCode === 9 && e.shiftKey === false && $navHolder.hasClass(ACTIVE_MENU_CLASS)) {
        e.preventDefault();
        $navOpener.focus();
      }
    });

    $document.on('click touchstart', e => {
      if (!$(e.target).closest('.main-nav-box').length && $window.width() > 749 && $body.hasClass(ACTIVE_MENU_CLASS) ) {
        $navHolder.removeClass(ACTIVE_MENU_CLASS);
        $navOpener.attr('aria-expanded', 'false');
        $nav.attr('aria-hidden', 'true');
        _removeBodyScroll();
      }
    });
  }

  function _removeBodyScroll() {
    if ($body.hasClass(ACTIVE_MENU_CLASS)) {
      $body.removeClass(ACTIVE_MENU_CLASS);
      $(window).scrollTop(topPosition);

    } else {
      topPosition = $window.scrollTop();
      $('#page-content-holder').css('top', -topPosition);

      $body.addClass(ACTIVE_MENU_CLASS);
    }
  }

  return {
    init,
  }
})();

export default mainMenu;