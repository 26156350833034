require('url-polyfill');
require('url-search-params-polyfill');
const axios = require('axios');

const eventsPage = (() => {
  function init() {
    if (!document.getElementById('events-list-section')) {
      return;
    }

    Vue.component('events-page', {
      data() {
        return {
          events: null,
          paginatedItems: [],
          currentPage: 1,
          pages: [],
          perPage: 9,
          pagesQty: 1,
          loaded: false,
          loadError: false,
          noEvents: false,
          pageUlr: null,
          history: [],
        };
      },

      props: ['events-data-url'],

      template: `
          <div class="container">
            <div v-if="loadError" class="error-msg">Data loading error... We’re sorry,  please try again later</div>
            <div v-else-if="noEvents" class="no-events">There are no upcoming events. Please check back later.</div>
            <div v-else-if="loaded" class="events-list">

              <div v-for="(item, index) in paginatedItems" :key="'eventKey' + index" class="events__item events__item--lg">
                <a :href="'https://mcla.presence.io/event/' + item.EventUri" class="events__link" target="_blank" rel="noopener">
                  <div class="events__date">{{getEventDate(item.startLocal)}}</div>
                  <h2 class="events__title">{{item.eventTitle}}</h2>
                  <div class="events__details">
                    <div class="time">{{getEventStart(item.startLocal)}}</div>
                    <div class="location">{{item.eventLocation}}</div>
                  </div>
                </a>
              </div>

            </div>
            <div v-else class="loader">Loading...</div>
            <div v-if="loaded && pagesQty !== 1" class="pagination">
              <button @click="prevPage" type="button" :class="{disabled: currentPage === 1}" class="pagination__arrow pagination__prev">
                <span class="sr-only">Previous</span>
                <span class="icon-arrow-fat"></span>
              </button>
              <ul class="pagination-list">

                <li v-for="page in pages" key="page.id" class="pagination-list__item">
                  <a href="#" @click.prevent="setActivePage(page.id)" :class="{current: +page.id === +currentPage}" class="pagination-list__link" :title="'page' + page.id">{{page.id}}</a>
                </li>

              </ul>
              <button @click="nextPage" type="button" :class="{disabled: currentPage === pagesQty}" class="pagination__arrow pagination__next">
                <span class="sr-only">Next</span>
                <span class="icon-arrow-fat"></span>
              </button>
            </div>
          </div>
      `,

      created() {
        window.addEventListener('popstate', () => {
          this.getPageUrlState();
        });
      },

      async mounted() {
        await this.getEvents();

        if (this.loadError) {
          return;
        }

        this.pagesQty = Math.ceil(this.events.length / this.perPage);

        if (+this.currentPage > this.pagesQty || +this.currentPage < 1) {
          this.currentPage = 1;
        }

        this.setPagination();
      },

      watch: {
        currentPage() {
          this.paginate(this.events);
        },
      },

      methods: {
        async getEvents() {
          const eventsData = await axios
            .get(this.$props.eventsDataUrl)
            .then(response => response.data)
            .catch(error => {
              this.loadError = true;
              console.error(error);
            });

          if (this.loadError) {
            return;
          }

          const filteredEvents = [];
          const descr = 'Public+Community';

          eventsData.events = eventsData.events.sort((a, b) => {
            return new Date(a.startLocal) - new Date(b.startLocal);
          });

          eventsData.events.forEach(el => {
            if (el.eventElements.length !== 0) {
              const cats = el.eventElements;
              if (cats.find(o => o.eleDescr === descr)) {
                filteredEvents.push(el);
              }
            }
          });

          if (filteredEvents.length === 0) {
            this.noEvents = true;
          }

          this.events = filteredEvents;
          this.getPageUrlState();
          this.paginate(this.events);
          this.setPageUrlState();
          this.loaded = true;
        },

        paginate(data) {
          const startPageItem =
            this.currentPage === 1
              ? 0
              : this.currentPage * this.perPage - this.perPage;
          const lastPageItem =
            this.currentPage === 1
              ? this.perPage
              : this.currentPage * this.perPage;
          this.paginatedItems = data.slice(startPageItem, lastPageItem);
        },

        setActivePage(pageId) {
          this.currentPage = pageId;
          this.setPageUrlState();
        },

        nextPage() {
          if (this.currentPage < this.pagesQty) {
            this.currentPage += 1;
            this.setPageUrlState();
          }
        },

        prevPage() {
          if (this.currentPage > 1) {
            this.currentPage -= 1;
            this.setPageUrlState();
          }
        },

        setPagination() {
          this.pages = [];

          for (let i = 1; i <= this.pagesQty; i++) {
            this.pages.push({
              id: i,
            });
          }
        },

        getPageUrlState() {
          this.pageUlr = new URL(window.location);
          this.currentPage = !this.pageUlr.searchParams.get('currentPage')
            ? this.currentPage
            : +this.pageUlr.searchParams.get('currentPage');
          this.perPage = !this.pageUlr.searchParams.get('perPage')
            ? this.perPage
            : +this.pageUlr.searchParams.get('perPage');
        },

        setPageUrlState() {
          if (!this.pageUlr.searchParams.get('currentPage')) {
            this.pageUlr.searchParams.append('currentPage', this.currentPage);
          } else {
            this.pageUlr.searchParams.set('currentPage', this.currentPage);
          }

          if (!this.pageUlr.searchParams.get('perPage')) {
            this.pageUlr.searchParams.append('perPage', this.perPage);
          } else {
            this.pageUlr.searchParams.set('perPage', this.perPage);
          }

          window.history.pushState({}, '', this.pageUlr.href);
        },

        getEventDate(date) {
          const itemDate = new Date(date);
          const itemDateMonth = itemDate.toLocaleString('en-GB', {
            month: 'short',
          });
          const itemDateDay = itemDate.getDate();
          return `${itemDateMonth} ${itemDateDay}`;
        },

        getEventStart(dateStart) {
          function addZero(i) {
            if (i < 10) {
              i = `0${i}`;
            }
            return i;
          }

          const itemDate = new Date(dateStart);
          let startHours = itemDate.getHours();
          let startMinutes = addZero(itemDate.getMinutes());
          const ampm = startHours >= 12 ? 'P.M.' : 'A.M.';
          startHours %= 12;
          startHours = startHours || 12;
          startMinutes = startMinutes === 0 ? '00' : startMinutes;
          return `${startHours}:${startMinutes} ${ampm}`;
        },
      },
    });

    new Vue({ el: '#events-list-section' });
  }

  return {
    init,
  };
})();

export default eventsPage;
