const fancyboxPopups = (() => {

  function init() {

    $('.popup-play-btn').fancybox({
      autoFocus: true,
      youtube: {
        controls: 1,
        showinfo: 1
      },
      afterShow: function (instance, current) {
        $('.fancybox-inner .fancybox-content').prepend('<button type="button" data-fancybox-close="" class="fancybox-button fancybox-button--close custom-close" title="Close"></button>');
        setTimeout(() => {
          $('.fancybox-inner .custom-close').focus();
        }, 200);
      },
    });
  }

  return {
    init,
  }
})();

export default fancyboxPopups;