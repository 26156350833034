const alert = (() => {
  function init() {
    const $body = $('body');
    const $alert = $('.alert');
    const $navOpenerMobile = $('.main-nav-opener.mobile');
    const ACTIVE_MENU_CLASS = 'active-main-menu';

    if (!$alert.length) {
      return;
    }

    const alertHeight = $alert.innerHeight();

    $navOpenerMobile.on('click', () => {
      if ($body.hasClass(ACTIVE_MENU_CLASS)) {
        $alert.slideUp(500);
      } else {
        setTimeout(() => {
          $alert.slideDown(500);
        }, 200);
      }
    });
  }

  return {
    init,
  };
})();

export default alert;