const imgCoverBg = (() => {
  function init() {
    $('.community-list__item').each((i, el) => {
      const $this = $(el);
      $this.css({'background-image' : $this.find('img').attr('src')});
    });
  }

  return {
    init,
  }
})();

export default imgCoverBg;