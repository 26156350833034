const twitterLoader = (() => {

  const init = () => {
    if (!$('.twitter__feed').length) {
      return;
    }

    // eslint-disable-next-line global-require
    require('../vendor/tweetie.min.js');

    const tweetun = $('.twitter__feed').data('un');

    $('.twitter__feed').tweetie({
      type: 'timeline',
      template: `<div class="twitter__item column__col">
          <div class="twitter__body">
            {{tweet.text}}
          </div>
          <time class="twitter__time">
            <a href="https://www.twitter.com/{{tweet.user.screen_name}}">
              {{tweet.created_at}}
            </a>
          </time>
        </div>`,
      dateFormat: '%b %d',
      url: '/assets/api/server.php',
      params: {
        count: 3,
        screen_name: tweetun,
        include_rts: true,
      },
    });
  };

  return {
    init,
  };
})();

export default twitterLoader;
