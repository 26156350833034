"use strict";
var _extends = Object.assign || function (e) {
  for (var t = 1; t < arguments.length; t++) {
    var r = arguments[t];
    for (var n in r) Object.prototype.hasOwnProperty.call(r, n) && (e[n] = r[n])
  }
  return e
};
! function (e) {
  function t(n) {
    if (r[n]) return r[n].exports;
    var a = r[n] = {
      i: n,
      l: !1,
      exports: {}
    };
    return e[n].call(a.exports, a, a.exports, t), a.l = !0, a.exports
  }
  var r = {};
  t.m = e, t.c = r, t.i = function (e) {
    return e
  }, t.d = function (e, r, n) {
    t.o(e, r) || Object.defineProperty(e, r, {
      configurable: !1,
      enumerable: !0,
      get: n
    })
  }, t.n = function (e) {
    var r = e && e.__esModule ? function () {
      return e.default
    } : function () {
      return e
    };
    return t.d(r, "a", r), r
  }, t.o = function (e, t) {
    return Object.prototype.hasOwnProperty.call(e, t)
  }, t.p = "", t(t.s = 1)
}([function (e, t, r) {
  Object.defineProperty(t, "__esModule", {
    value: !0
  }), t.default = function (e, t, r) {
    return e.text = t.replace(/{{tweet\.(.*?)}}/gi, function (t, a) {
      var u = function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
          t = (arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "").replace(/\[(\w+)\]/g, ".$1").split("."),
          r = e[t[0]];
        return t.slice(1).every(function (e) {
          return !!(r = r.hasOwnProperty(e) ? r[e] : null)
        }), r
      }(e, a);
      return n.isDate(a) && (u = n.format(u, r)), u
    }), e.text = (0, a.default)(e.text, e.entities), e.text
  };
  var n = function (e) {
      if (e && e.__esModule) return e;
      var t = {};
      if (null != e)
        for (var r in e) Object.prototype.hasOwnProperty.call(e, r) && (t[r] = e[r]);
      return t.default = e, t
    }(r(2)),
    a = function (e) {
      return e && e.__esModule ? e : {
        default: e
      }
    }(r(3))
}, function (e, t, r) {
  var n = function (e) {
    return e && e.__esModule ? e : {
      default: e
    }
  }(r(0));
  ! function (e) {
    e.fn.tweetie = function (t, r) {
      var a = this,
        u = _extends({
          url: "api/tweet.php",
          type: "timeline",
          params: {},
          template: "{{tweet.created_at}} - {{tweet.text}}",
          dateFormat: "%m/%d/%Y"
        }, t);
      e.get(u.url, {
        type: u.type,
        params: u.params
      }).done(function (e) {
        var t = u.template,
          o = u.dateFormat;
        a.empty(), e.forEach(function (e) {
          var r = (0, n.default)(e, t, o);
          a.append(r)
        }), "function" == typeof r && r()
      }).fail(function (t) {
        if (t.responseJSON) {
          var n = t.responseJSON.errors;
          n && (n.forEach(function (t) {
            return e.error(t.message)
          }), "function" == typeof r && r(n))
        }
        else e.error(t), "function" == typeof r && r(t)
      })
    }
  }(jQuery)
}, function (e, t, r) {
  function n(e) {
    return ~e.indexOf("created_at")
  }
  Object.defineProperty(t, "__esModule", {
    value: !0
  }), t.isDate = n, t.format = function (e, t) {
    var u = e.split(" ");
    return n = {
      "%d": (r = new Date(Date.parse(u[1] + " " + u[2] + ", " + u[5] + " " + u[3] + " UTC"))).getDate(),
      "%m": r.getMonth() + 1,
      "%b": a[r.getMonth()].substr(0, 3),
      "%B": a[r.getMonth()],
      "%y": r.getFullYear().toString().slice(-2),
      "%Y": r.getFullYear()
    }, t.replace(/%(.)/g, function (e) {
      return n[e]
    })
  };
  var a = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
}, function (e, t, r) {
  Object.defineProperty(t, "__esModule", {
    value: !0
  }), t.default = function (e, t) {
    return t.urls && t.urls.length && t.urls.forEach(function (t) {
      return e = function (e, t) {
        return e.replace(t.url, '<a href="' + t.expanded_url + '" target="_blank">' + t.display_url + "</a>")
      }(e, t)
    }), t.hashtags && t.hashtags.length && t.hashtags.forEach(function (t) {
      return e = function (e, t) {
        var r = t.text;
        return e.replace("#" + r, '<a href="https://twitter.com/hashtag/' + r + '?src=hash" target="_blank">#' + r + "</a>")
      }(e, t)
    }), e = function (e) {
      return e.replace(/@([a-zA-Z0-9_]+)/g, '<a href="https://twitter.com/$1" target="_blank">@$1</a>')
    }(e)
  }
}]);
