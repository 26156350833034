const axios = require('axios');

const firstEvents = (() => {
  async function init($eventsContainer, itemsQty, descr, itemClassName) {
    const $_eventsContainer = $eventsContainer;
    let hasError = false;

    const filteredEvents = [];

    let eventsData = await axios
      .get($_eventsContainer.attr('data-events-url'))
      .then(response => response.data)
      .catch(error => {
        hasError = true;
        console.error(error);
      });

    eventsData = eventsData.events.sort((a, b) => {
      return new Date(a.startLocal) - new Date(b.startLocal);
    });

    if (!hasError) {
      eventsData.forEach(el => {
        if (el.eventElements.length !== 0) {
          const cats = el.eventElements;
          if (cats.find(o => o.eleDescr === descr)) {
            filteredEvents.push(el);
          }
        }
      });
    }

    for (let i = 0; i < itemsQty; i++) {
      if (hasError) {
        const $errorTemplate = $(`
          <div class="${itemClassName}">
            <div class="events__link">
              <span class="error-msg">Error fetching data...</span>
            </div>
          </div>
        `);

        $_eventsContainer.append($errorTemplate);
      } else {
        const item = filteredEvents[i];

        if (item === undefined) {
          return;
        }

        const $template = $(`
          <div class="${itemClassName}">
            <a href="https://mcla.presence.io/event/${item.EventUri}" target="_blank" rel="noopener" class="events__link">
              <div class="events__date">${getEventDate(item.startLocal)}</div>
              <h2 class="events__title">${item.eventTitle}</h2>
              <div class="events__details">
                <div class="time">${getEventStart(item.startLocal)}</div>
                <div class="location">${item.eventLocation}</div>
              </div>
            </a>
          </div>
        `);

        $_eventsContainer.append($template);
      }
    }

    function addZero(i) {
      if (i < 10) {
        i = `0${i}`;
      }
      return i;
    }

    function getEventStart(dateStart) {
      const itemDate = new Date(dateStart);
      let startHours = itemDate.getHours();
      let startMinutes = addZero(itemDate.getMinutes());
      let ampm = startHours >= 12 ? 'P.M.' : 'A.M.';
      startHours = startHours % 12;
      startHours = startHours ? startHours : 12;
      startMinutes = startMinutes === 0 ? '00' : startMinutes;
      return `${startHours}:${startMinutes} ${ampm}`;
    }

    function getEventDate(date) {
      const itemDate = new Date(date);
      const itemDateMonth = itemDate.toLocaleString('en-GB', { month: 'short' });
      const itemDateDay = itemDate.getDate();
      return `${itemDateMonth} ${itemDateDay}`;
    }
  }

  return {
    init,
  };
})();

export default firstEvents;
