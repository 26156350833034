/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

const carousel = (() => {
  // Render carousel
  function renderCarousel() {
    $('.carousel__wrapper').on('init', function(){
      $('.carousel .slick-active .carousel__img').each((i, el) => {
        const $el = $(el);

        $el.closest('.carousel-holder').find('.slick-arrow, .slick-dots').css({
          'top' : $el.outerHeight()
        });
      });
    });

    $('.carousel__wrapper').slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      fade: true,
      cssEase: 'linear',
      appendDots: $('.carousel-holder'),
      prevArrow: $('.carousel-holder .slick-prev'),
      nextArrow: $('.carousel-holder .slick-next'),
    });

    $('.carousel__wrapper').on('afterChange', function(event, slick, currentSlide){
      const NAV_TOP_POSITION = $(slick.$slides[currentSlide]).find('.carousel__img').outerHeight();

      slick.$dots.css({
          'top' : NAV_TOP_POSITION
        });

      slick.$nextArrow.css({
          'top' : NAV_TOP_POSITION
        });

      slick.$prevArrow.css({
        'top' : NAV_TOP_POSITION
      });
    });
  }

  const init = () => {
    const $window = $(window);
    let resizeTimer = null;

    if (!$('.carousel').length) {
      return;
    }

    $('.carousel .carousel__img').each((i, el) => {
      const $el = $(el);

      $el.css( {'background-image': `url('${$el.find('img').css({ 'opacity' : 0 }).attr('src')}')`});
    });

    renderCarousel();

    $window.resize(() => {
      clearTimeout(resizeTimer);

      resizeTimer = setTimeout(() => {
        $('.carousel .slick-active .carousel__img').each((i, el) => {
          const $el = $(el);

          $el.closest('.carousel-holder').find('.slick-arrow, .slick-dots').css({
            'top' : $el.outerHeight()
          });
        });
      }, 200);
    });
  };

  return {
    init,
  };
})();

export default carousel;
