import firstEvents from './firstEvents';

const homePageEvents = (() => {
  function init() {
    const $eventsContainer = $('#homepage-events');

    if (!$eventsContainer.length) {
      return;
    }

    const itemsQty = 3;
    const descr = 'Public';
    const itemClassName = 'events__item';

    setTimeout(function eventContainer() {
      if ($('.events__item').length) {
        $eventsContainer.closest('.container').addClass('has-events');
      }
    }, 300);

    firstEvents.init($eventsContainer, itemsQty, descr, itemClassName);
  }

  return {
    init,
  };
})();

export default homePageEvents;
